<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter :headers="payHeaders" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="payHeaders"
            :items="payOrders"
            :options.sync="pagination"
            :server-items-length="totalPayOrders"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{item.ID}}</td>
                <td>{{ item.CustomerName }}</td>
                <td class="text-end font-weight-bold">{{item.TotalAmountVND | currency}}</td>
                <td class="text-end">{{ item.Deposit | currency}}</td>
                <td class="text-end">{{ item.PayLeft | currency}}</td>
                <td class="text-end">{{ item.Wallet | currency}}</td>
                <td class="text-end font-weight-bold">{{ item.Remain | currency}}</td>
                <td class="text-end">{{ item.OrderNumber | currency}}</td>
                <td class="text-end">{{ item.OrderNumberTran | currency}}</td>
                <td class="text-center pay-button">
                  <v-btn text icon color="red lighten-2" @click="openPayDetail(item.ID)">
                    <v-icon>fa-check-double</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="dialogPayDetail" max-width="700px">
            <v-card>
              <v-card-title>
                <span class="title">Thanh toán đơn hàng</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md pa-0>
                  <v-layout row wrap>
                    <v-flex lg12>
                      <div>Mã khách hàng: {{payOrderDetail.CustomerID}}</div>
                    </v-flex>
                    <v-flex lg12>
                      <div>Tài khoản: {{payOrderDetail.CustomerName}}</div>
                    </v-flex>
                    <v-flex lg12>
                       <v-text-field :disabled="customerServiceRole" v-model="phoneNumber" label="Điện thoại" class="input-group--focused"></v-text-field>
                    </v-flex>
                    <v-flex lg12>
                       <v-text-field :disabled="customerServiceRole" v-model="payOrderDetail.Address" label="Địa chỉ" class="input-group--focused"></v-text-field>
                    </v-flex>
                     <v-flex lg12>
                       <v-text-field :disabled="customerServiceRole" v-model="shippingMethod" label="Hình thức ship" class="input-group--focused"></v-text-field>
                    </v-flex>
                    <v-flex lg12 class="font-weight-bold">
                      <div>Số đơn thanh toán: {{selected.length | currency}}</div>
                    </v-flex>
                    <v-flex lg12>
                      <v-tabs center-active background-color="primary" dark v-model="selectedTab">
                        <v-tab key="0">Chưa xuất kho</v-tab>
                        <v-tab key="1">Chờ xuất kho</v-tab>
                        <v-tab key="2">Đã xuất kho</v-tab>
                      </v-tabs>
                      <v-data-table
                        :headers="orderHeader"
                        :items="diplayOrders"
                        :mobile-breakpoint="100"
                        disable-pagination
                        :hide-default-footer="true"
                        item-key="ID"
                        show-select
                        v-model="selected"
                      >
                        <template v-slot:item="{ item, isSelected, select }">
                          <tr :active="isSelected" @click="select(!isSelected)">
                            <td>
                              <v-checkbox primary hide-details :input-value="isSelected"></v-checkbox>
                            </td>
                            <td>
                              <a target="blank" :href="item.OrderType == 1 ? '/don-hang/' : '/ky-gui/' + item.ID">{{(item.OrderType == 1 ? 'MD' : 'KG') +item.ID}}</a>
                            </td>
                            <td v-if="item.Status != orderStatus.da_xuat_kho" :class="item.Deliverable ? 'red--text' : ''">{{item.Deliverable ? 'Cho xuất kho' : ''}}</td>
                            <td v-if="item.Status == orderStatus.da_xuat_kho">{{item.Status | order_status}}</td>
                            <td
                              class="text-end font-weight-bold"
                            >{{ item.TotalAmountVND | currency}}</td>
                            <td class="text-end">{{ item.Deposit | currency}}</td>
                            <td class="text-end">{{ item.PayLeft | currency}}</td>
                          </tr>
                        </template>
                        <template v-slot:body.append>
                          <tr>
                            <td colspan="3" class="font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{totalAmount | currency}}</td>
                            <td class="text-end font-weight-bold">{{totalDeposit | currency}}</td>
                            <td class="text-end font-weight-bold">{{totalPayLeft | currency}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="font-weight-bold">Phí ship</td>
                            <td class="text-end font-weight-bold">
                              <h-currency-input
                                :disabled="customerServiceRole"
                                class="fee-cn-footer"
                                v-model="shipFee"
                                :decimal="0"
                              ></h-currency-input>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" class="font-weight-bold"><v-text-field label="Phí khác" v-model="feeOtherName" :disabled="customerServiceRole"></v-text-field></td>
                            <td class="text-end font-weight-bold">
                              <h-currency-input
                                :disabled="customerServiceRole"
                                class="fee-cn-footer"
                                v-model="feeOther"
                                :decimal="0"
                              ></h-currency-input>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" class="font-weight-bold">Số dư ví</td>
                            <td
                              class="text-end font-weight-bold"
                            >{{payOrderDetail.Wallet | currency}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="font-weight-bold">Cần trả thêm</td>
                            <td class="text-end font-weight-bold">{{totalRemain | currency}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="font-weight-bold"><v-select label="Hình thức thanh toán" v-model="method" :items="rechargeMethods" item-text="name" item-value="value" :disabled="customerServiceRole"></v-select></td>
                            <td class="text-end font-weight-bold">
                              <h-currency-input
                                :disabled="customerServiceRole"
                                class="fee-cn-footer"
                                v-model="inputCash"
                                :decimal="0"
                              ></h-currency-input>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-3 white--text" @click="dialogPayDetail = false">Đóng</v-btn>
                <v-btn
                  color="black darken-3 white--text"
                  @click="saveDeliverOrder(0)"
                  v-if="!customerServiceRole"
                  :disabled="selected.length == 0"
                >Huỷ xuất kho</v-btn>
                <v-btn
                  color="red darken-3 white--text"
                  @click="saveDeliverOrder(1)"
                  v-if="!customerServiceRole"
                  :disabled="selected.length == 0"
                >Xuất kho</v-btn>
                <v-btn
                  color="green darken-3 white--text"
                  @click="savePayOrder"
                  v-if="!customerServiceRole"
                  :disabled="selected.length == 0"
                >Thanh toán</v-btn>
                <v-btn absolute top right class="mt-5" @click="printPayOrder">
                  <v-icon color="red darken-2" class="mr-2">fa-print</v-icon>In đơn
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="loading" fullscreen>
            <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
              <v-layout justify-center align-center>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-layout>
            </v-container>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="pt-5">
      <h3>Lịch sử thanh toán</h3>
    </div>
    <payment-history class="px-0"></payment-history>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";
import { currency } from "@/commons/currency";
import { sendErrorNotice, getCurrentTime } from "@/commons/utils";
import PaymentHistoryList from "@/views/PaymentHistory/PaymentHistoryList.vue";
export default {
  components: {
    "hpo-filter": Filtering,
    "payment-history": PaymentHistoryList
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      timeout: 2000,
      payMode: false,
      search: "",
      feeOtherName: "",
      feeOther: 0,
      inputCash: 0,
      shipFee: 0,
      selectedTab: 0,
      method: configs.RECHARGE_TYPE.Cash,
      shippingMethod: "",
      rechargeMethods: _.filter(configs.RECHARGE_METHODS, function(o) { return o.value >= 90; }),
      payHeaders: [
        {
          text: "Mã khách hàng",
          value: "ID",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Khách hàng",
          value: "CustomerName",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Tổng tiền",
          value: "TotalAmountVND",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Đã trả",
          value: "Deposit",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Tiền thiếu",
          value: "PayLeft",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Tài khoản",
          value: "Wallet",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Cần trả thêm",
          value: "Remain",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Số đơn hàng",
          value: "OrderNumber",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Đơn đang vận chuyển",
          value: "OrderNumberTran",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          align: "center",
          filterable: false,
          sortable: false
        }
      ],
      orderHeader: [
        {
          text: "Mã đơn hàng",
          value: "ID",
          sortable: false,
          right: false
        },
        {
          text: "Trạng thái",
          value: "Deliverable",
          sortable: false,
          right: false
        },
        {
          text: "Tổng tiền",
          value: "TotalAmountVND",
          sortable: false,
          align: "end"
        },
        {
          text: "Đã trả",
          value: "Deposit",
          sortable: false,
          align: "end"
        },
        {
          text: "Còn thiếu",
          value: "PayLeft",
          sortable: false,
          align: "end"
        }
      ],
      selected: [],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "payorder_list",      
      filterConditions: [],
      quickFilterConditions: [],
      dialogPayDetail: false,
      phoneNumber: '',
      orderStatus: configs.ORDER_STATUS_ENUM
    };
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
        this.filter_data();
      },
      deep: true
    },
    payOrderDetail() {
      // if (this.payOrderDetail && this.payOrderDetail.Orders) {
      //   this.selected = this.payOrderDetail.Orders.slice();
      // }
      this.phoneNumber = this.payOrderDetail ? this.payOrderDetail.PhoneNumber : '';
    }
  },
  computed: {
    ...mapState("payOrder", {
      payOrders: "all",
      totalPayOrders: "total",
      payOrderDetail: "selectedItem",
      loading: "loading",
    }),
    pages() {
      if (this.options.rowsPerPage == null || this.options.totalItems == null)
        return 0;

      return Math.ceil(this.options.totalItems / this.options.rowsPerPage);
    },
    totalAmount() {
      return _.sumBy(this.selected, "TotalAmountVND");
    },
    totalDeposit() {
      return _.sumBy(this.selected, "Deposit");
    },
    totalPayLeft() {
      return _.sumBy(this.selected, "PayLeft");
    },
    totalRemain() {
      return this.payOrderDetail.Wallet >= this.totalPayLeft + this.shipFee + this.feeOther
        ? 0
        : this.totalPayLeft + this.shipFee + this.feeOther - this.payOrderDetail.Wallet;
    },
    customerServiceRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService || (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
    },
    diplayOrders() {
      if(this.selectedTab == 0) {
        return _.filter(this.payOrderDetail.Orders, (item) => {return item.Status != this.orderStatus.da_xuat_kho && !item.Deliverable});
      }
      if(this.selectedTab == 1) {
        return _.filter(this.payOrderDetail.Orders, (item) => {return item.Status != this.orderStatus.da_xuat_kho && item.Deliverable});
      }
      return _.filter(this.payOrderDetail.Orders, (item) => {return item.Status == this.orderStatus.da_xuat_kho});
    }
  },
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      this.$store.dispatch("payOrder/getPayOrderList", param);
    },
    toggleAll() {
      if (this.selected.length === this.payOrderDetail.Orders.length)
        this.selected = [];
      else this.selected = this.payOrderDetail.Orders.slice();
    },
    openPayDetail(customerId) {
      this.$store.dispatch("payOrder/getPayOrderDetail", customerId);
      this.selected = [];
      this.feeOtherName = "";
      this.feeOther = 0;
      this.inputCash = 0;
      this.shipFee = 0;
      this.selectedTab = 0;
      this.method = configs.RECHARGE_TYPE.Cash;
      this.shippingMethod = "";
      this.dialogPayDetail = true;
    },
    saveDeliverOrder(deliverable = 1) {
      let order_ids = _.map(_.filter(this.selected, {'OrderType': 1}), "ID");
      if(order_ids.length > 0) {
        this.$store.dispatch("main_order/updateMultiple", {
          OrderIDs: order_ids,
          Deliverable: deliverable
        });
      }
      let deposit_ids = _.map(_.filter(this.selected, {'OrderType': 2}), "ID");
      if(deposit_ids.length > 0) {
        this.$store.dispatch("deposit/updateMultiple", {
          OrderIDs: deposit_ids,
          Deliverable: deliverable
        });
      }
      this.$store.dispatch("orderTransaction/updateMultiple", {
        OrderIDs: order_ids,
        DepositIDs: deposit_ids,
        ShippingInfo: `${this.payOrderDetail.CustomerName} - ${this.phoneNumber} - ${this.payOrderDetail.Address}`,
        ShippingMethod: this.shippingMethod
      });
      this.dialogPayDetail = false;
      setTimeout(this.filter_data, 300);
    },
    savePayOrder() {
      if (this.selected.length == 0) {
        sendErrorNotice("Hãy chọn hóa đơn cần thanh toán");
        return;
      }
      if (this.totalRemain > this.inputCash) {
        sendErrorNotice("Hãy nhập thêm tiền mặt");
        return;
      }
      this.$store.dispatch("payOrder/updatePayOrder", {
        order_ids: _.map(_.filter(this.selected, {'OrderType': 1}), "ID"),
        deposit_ids: _.map(_.filter(this.selected, {'OrderType': 2}), "ID"),
        cash: this.inputCash,
        method: this.method,
        shipFee: this.shipFee,
        feeOther: this.feeOther,
        feeOtherName: this.feeOtherName,
        customer_id: this.payOrderDetail.CustomerID,
        shippingMethod: this.shippingMethod
      });
      this.$store.dispatch("orderTransaction/updateMultiple", {
        OrderIDs: _.map(_.filter(this.selected, {'OrderType': 1}), "ID"),
        DepositIDs: _.map(_.filter(this.selected, {'OrderType': 2}), "ID"),
        ShippingInfo: `${this.payOrderDetail.CustomerName} - ${this.phoneNumber} - ${this.payOrderDetail.Address}`,
        ShippingMethod: this.shippingMethod
      });
      this.dialogPayDetail = false;
      setTimeout(this.filter_data, 300);
    },
    printPayOrder() {
      var mywindow = window.open("", "PRINT");

      mywindow.document.write(`<html><head><title></title>

      <style>
      .row {
          display: flex;
      }

      .flex {
          flex: 1 1 auto;
      }

      .flex.lg6 {
          flex-basis: 50%;
          flex-grow: 0;
          max-width: 50%;
      }

      .flex.lg8 {
          flex-basis: 66%;
          flex-grow: 0;
          max-width: 66%;
      }

      .flex.lg4 {
          flex-basis: 34%;
          flex-grow: 0;
          max-width: 34%;
      }

      .flex.lg3 {
          flex-basis: 25%;
          flex-grow: 0;
          max-width: 25%;
      }

      .flex.lg12 {
          flex-basis: 100%;
          flex-grow: 0;
          max-width: 100%;
      }

      .title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
      }

      .sub-title {
          font-size: 16px;
          font-weight: bold;
      }

      .l-text {
          text-align: left;
      }

      .r-text {
          text-align: right;
      }

      .note-text {
          font-size: 14px;
          font-weight: bold;
          font-style: italic;
          text-align: center;
      }

      .body-text {
          font-size: 14px;
      }

      table {
          border-collapse: collapse;
          width: 100%;
      }

      td,
      th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
      }

      hr {
          border: 0.5px solid #dddddd;
      }

      tr,
      td,
      th {
          page-break-inside: avoid !important;
      }

      @media print {
          @page {
              size: auto;
              margin: 10mm 15mm 10mm 15mm;
          }

          html {
              background-color: #FFFFFF;
              margin: 0px;
          }

          body {
              margin: 0mm 0mm 0mm 0mm;
          }
      }
      </style></head><body style='width:210mm'>`);

      mywindow.document.write(`<div class='layout wrap'>
            <div class='flex lg12'><p class='title'>Hóa đơn thanh toán</p></div>
            <div class='row'><div class='flex lg8'></div><div class='flex lg4'><p class='body-text r-text'>Ngày in đơn: ${getCurrentTime().format(configs.SHORT_DATE_FORMAT)}</p></div></div>
            <div class='flex lg12'><hr></div>
            <div class='row'><div class='flex lg12'><p class='body-text l-text'>Khách hàng: ${this.payOrderDetail.CustomerName}</p></div></div>
            <div class='row'><div class='flex lg12'><p class='body-text l-text'>Điện thoại: ${this.phoneNumber}</p></div></div>
            <div class='row'><div class='flex lg12'><p class='body-text l-text'>Địa chỉ: ${this.payOrderDetail.Address}</p></div></div>
            <div class='row'><div class='flex lg12'><p class='body-text l-text'>Số đơn thanh toán: ${this.selected.length}</p></div></div>`);
      mywindow.document.write(`<table>
            <tr> <th>Mã đơn hàng</th> <th class='body-text r-text'>Tổng tiền</th> <th class='body-text r-text'>Đã trả</th> <th class='body-text r-text'>Còn thiếu</th></tr>`);
      for (let i = 0; i < this.selected.length; i++) {
        const element = this.selected[i];
        mywindow.document.write(
          `<tr> <td>${(element.OrderType == 1 ? 'MD' : 'KG') + element.ID}</td> <td class='body-text r-text'>${currency(
            element.TotalAmountVND
          )}</td> <td class='body-text r-text'>${currency(
            element.Deposit
          )}</td> <td class='body-text r-text'>${currency(
            element.PayLeft
          )}</td></tr>`
        );
      }
      let phi_khac = this.feeOtherName != '' ? `<tr> <td class='sub-title' colspan='3'>${this.feeOtherName}</td><td class='sub-title r-text'>${currency(this.feeOther)}</td></tr>` : ''
      mywindow.document
        .write(`<tr> <td class='sub-title'>Tổng</td> <td class='sub-title r-text'>${currency(
        this.totalAmount
      )}</td> <td class='sub-title r-text'>${currency(
        this.totalDeposit
      )}</td> <td class='sub-title r-text'>${currency(
        this.totalPayLeft
      )}</td></tr>
            <tr> <td class='body-text' colspan='3'>Số dư ví</td><td class='body-text r-text'>${currency(
              this.payOrderDetail.Wallet
            )}</td></tr>
            <tr> <td class='body-text' colspan='3'>Phí ship</td><td class='body-text r-text'>${currency(
              this.shipFee
            )}</td></tr>
            ${phi_khac}
            <tr> <td class='body-text' colspan='3'>Cần trả thêm</td><td class='body-text r-text'>${currency(
              this.totalRemain
            )}</td></tr>
            <tr> <td class='body-text' colspan='3'>Nạp tiền mặt</td><td class='body-text r-text'>${currency(
              this.inputCash
            )}</td></tr>
            </table>
            <hr class='v-divider theme--light'>
            <div class='flex lg12'><p class='note-text'>${configs.SYSTEM_CONFIG.domain}</p></div>
            </div>
            </body></html>`);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
      return true;
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    }
  },
  mounted() {
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
  }
};
</script>

<style>
.pay-button {
  width: 135px;
}

.fee-cn-footer .v-text-field .v-label--active {
  right: 0px !important;
  left: auto !important;
}

.fee-cn-footer .v-text-field__slot input {
  text-align: right !important;
  font-size: 0.9em !important;
}
</style>
